
import { Vue, Options } from 'vue-class-component';
import ProductTable from "../components/ProductTable.vue";
import axios from '@/plugins/axios';

interface Result {
    total: number,
    data: [],
}

@Options({
  components: {
    ProductTable,
  },
})

export default class ProductPage extends Vue {
    listProduct= []
    currentPage = 1
    totaRows = 0;
    perPage = 20;
    target = null
    filter = {}
    headers = {
        'token-user': localStorage.getItem('tokenUser'),
        'account': localStorage.getItem('account'),
    };

    async getList (page = 0) {
        await this.$store.dispatch("setLoading", true, { root: true });
        let res : Result = {
            total: 0,
            data: []
        };
        res = await axios.get('user/product', { 
            headers : {
                'token-user': localStorage.getItem('tokenUser'),
                'account': localStorage.getItem('account'),
                'connectionTo': this.target,
            },
            params: {
                'condition': this.filter,
                'account': localStorage.getItem('account'),
                'connectionTo': this.target,
                'page': page,
                'limit': this.perPage
            }
        })
        if (res) {
            this.totaRows = res.total
            this.listProduct = res.data
        }
        await this.$store.dispatch("setLoading", false, { root: true });
    }
    handleCurrentChange(index = 1) {
        this.currentPage = index
        this.getList(index)
    }
    handleSizeChange(val: number) {
        this.currentPage = 1
        this.perPage = val
        this.getList(this.currentPage)
    }

    handleAfter(){
        this.getList(0)
    }
    handleSearchProduct(data: any) {
        this.target = data.target
        this.filter = data.condition
        this.getList(0)  
    }

}
